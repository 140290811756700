import React, { useState, useContext } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFilter from "./TableFilter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, makeStyles } from "@material-ui/styles"; 
import styles from "./detailTables.module.scss";
import { monthName ,formatNumberMarktdatenTables} from "./utils";
import { withNamespaces } from "react-i18next";
import { columnNames, formatDate } from "./utils";
import DomainContext from "../../context/DomainContext";

import TableFooter from "./TableFooter";
const StyledTableCell = withStyles(theme => ({
  head: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",

    "@media (max-width: 1140px)": {
      padding: "10px 15px ",

      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },

    "@media (max-width: 768px)": {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "& th": {
      padding: "10px 16px ",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "left",
      letterSpacing: "0.5px",
      width: "512px",
      height: "31px",
    },

    "& td": {
      padding: "10px 16px ",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "right",
      letterSpacing: "0.5px",
      width: "512px",
      height: "31px",
    },

    "&:nth-of-type(odd)": {
      backgroundColor: "#F9FAFA",
    },

    "@media (max-width: 1140px)": {
      "& th": {
        padding: "10px 15px ",
        width: "381px",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        height: "27px",
      },

      "& td": {
        padding: "10px 15px ",
        fontSize: "14px",
        lineHeight: "20px",
        textAlign: "right",
        letterSpacing: "0.25px",
        width: "381px",
        width: "170px",
        height: "27px",
      },
    },
    "@media (max-width: 768px)": {
      "& th": {
        minWidth: "122px",
        width: "fit-content",
      },

      "& td": {
        width: "auto",
      },
    },
  },
}))(TableRow);

const DetailWithTwoColumn = props => {
  const {
    t,
    changeFilters,
    tableBody,
    changePageSize,
    pageSize,
    pageId,
    page,
    changePage,
    changeDateRange,
  } = props;
  var lastDate = null;

  const domain = useContext(DomainContext);

 
  return (
    <div className={`${styles.twoColumnsTable} ${styles.tableContainer}`}>
      <div className={styles.tableWrapper}>
        <TableFilter
          pageSize={pageSize}
          changePageSize={changePageSize}
          changeFilters={changeFilters}
          changeDateRange={changeDateRange}
        />

        <Table aria-label="customized table" className={styles.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell className={styles.tableHeadCell}>
                {t("Datum")}
              </StyledTableCell>
              <StyledTableCell align="right" className={styles.tableHeadCell}>
                {columnNames(pageId)}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBody.data !== undefined
              ? tableBody.data.map(row => {
                  let borderTop = false;
                  if (row.date !== null) {
                    let year = row.date.split("-");
                    year = year[2];
                    if (lastDate === null) {
                      lastDate = year;
                    }
                    borderTop = lastDate != year;
                    lastDate = year;
                  }

                  return (
                    <StyledTableRow
                      className={`${borderTop ? styles.borderTop : ""}`}
                    >
                      <StyledTableCell component="th" scope="row">
                        {formatDate(row.date , domain )}{" "}
                        {row.month != null ? t(monthName(row.month)) : ""}{" "}
                        {row.quarter != null ? "Q" + row.quarter : ""}{" "}
                        {row.year}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.rate === null
                          ? formatNumberMarktdatenTables(domain,row.price,3)
                          : formatNumberMarktdatenTables(domain,row.rate,3)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>

        <TableFooter
          changePage={changePage}
          count={tableBody.totalPages * pageSize}
          pageSize={pageSize}
          tableBody={tableBody}
          page={page}
        />
      </div>
    </div>
  );
};

export default withNamespaces()(DetailWithTwoColumn);
