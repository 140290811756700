import React from "react";
import { withNamespaces } from "react-i18next";

import Tabs from "../../components/marktdaten/Tabs";
import styles from "./index.module.scss";

const ToggleTableGraph = props => {
  const { t, tab, setTab, name } = props;

  return (
    <>
      <h1 className={styles.componentTitle}>{t(name)}</h1>
      <Tabs tab={tab} setTab={setTab} />
    </>
  );
};

export default withNamespaces()(ToggleTableGraph);
