import React, { useState, useEffect } from "react";
import styles from "./FooterAndFilters.module.scss";
import { withStyles } from "@material-ui/styles";
 
import { withNamespaces } from "react-i18next"; 
import TablePagination from "@material-ui/core/TablePagination";
import "./footerStyles.css";
import { Link } from "gatsby";
 
const TableFooter = props => {
  useEffect(() => {
    setHost(typeof window !== 'undefined' ?window.location.protocol + "//" + window.location.hostname  : '');  

  }, []); 
  const [host, setHost] = useState("");
  const { tableBody, page, pageSize, count, changePage,t  } = props;
  const StyledPagination = withStyles(theme => ({
    root: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.6)",
      "@media (max-width: 768px)": {
        "& .MuiTablePagination-toolbar": {
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
          display: "flex",
          justifyContent: "space-between",
          paddingBottom:"20px" 
       
        },
      },
    },  
    actions: { marginLeft: "9px" },
  }))(TablePagination);
  return (
    <div className={styles.tableFooterContainer}>
      <Link className={styles.disclaimerLink} to={host+"/marktdaten/disclaimer"}>
        {t("*Rechtliche Hinweise")}
      </Link>

      <StyledPagination
     
        rowsPerPageOptions={[]} 
        component="div"
        count={count} 
        rowsPerPage={pageSize}
        labelDisplayedRows={() => (
          <>
            {(page - 1) * pageSize + 1}-
            {page == tableBody.totalPages
              ? tableBody.totalRecords
              : page * pageSize}{" "}
            {t("von")} {tableBody.totalRecords}
          </>
        )}
        page={page - 1}
        onPageChange={changePage}
        onChangeRowsPerPage={"handleChangeRowsPerPage"}
      />
    </div>
  );
};

export default withNamespaces()(TableFooter);
