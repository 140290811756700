import React, { useState, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "gatsby";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import i18n from "../../i18n/i18n";
import styles from "./index.module.scss";
import SEOComponent from "../../components/Shared/Seo";
import NavMenu from "../../components/nav-menu/NavMenu";
import Footer from "../../components/main-page/sections/footer/Footer";
import GroupLegal from "../../components/group-legal/GroupLegal";
import Filter from "../../components/marktdaten/Filter";
import PriceChart from "../../components/marktdaten/PriceChart";
import MarketInsights from "../../components/main-page/sections/marketInsights/MarketInsights";
import OptimizeScrap from "../../components/optimize-scrap/OptimizeScrap";
import IncreaseRevenue from "../../components/main-page/sections/increaseRevenue/IncreaseRevenue";
import DetailTable from "../../components/marktdaten-tables/DetailTable";
import { setName } from "../../action/MarktdatenActions";
import ToggleTableGraph from "./toggleTableGraph";

const Material = props => {
  /**
   * PROPS
   */
  const {
    pathContext: { id, name, table, seoname, source },
  } = props;

  /**
   * STORE
   */
  const dispatch = useDispatch();
  const chartData = useSelector(state => state.marktdaten);

  const [tab, setTab] = useState("chart");
  const { intialDisplay } = chartData || {};

  useEffect(() => {
    dispatch(setName(name));
  }, [name, id, dispatch]);

  return (
    <div>
      <I18nextProvider i18n={i18n} defaultNS={"default"}>
        <SEOComponent page={seoname} />
        <NavMenu />

        <Breadcrumb
          arrPath={[
            { path: "/marktdaten", label: "Marktdaten" },
            { path: null, label: props.pageContext.name },
          ]}
        />

        <div>
          <ToggleTableGraph
            tab={tab}
            setTab={setTab}
            id={id}
            intialDisplay={intialDisplay}
            name={"h1_" + props.pageContext.name}
          />

          {intialDisplay === "graph" && (
            <div className={styles.graphContainer}>
              <Filter
                material={id}
                tableName={table}
                intialDisplay={intialDisplay}
              />
              <PriceChart material={id} tableName={table} />
            </div>
          )}

          {intialDisplay === "table" && (
            <DetailTable
              pathContext={props.pathContext}
              pageContext={props.pageContext}
              source={source}
            />
          )}
        </div>

        <OptimizeScrap />
        <Footer />
        <GroupLegal />
      </I18nextProvider>
    </div>
  );
};

export default React.memo(Material);
