import React, { useContext } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFilter from "./TableFilter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/styles";
import styles from "./detailTables.module.scss";
import { monthName, formatDate, formatNumberMarktdatenTables } from "./utils";
import { withNamespaces } from "react-i18next";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "./TableFooter";
import DomainContext from "../../context/DomainContext";
import LinkToSource from "./LinkToSource";

const StyledTableCell = withStyles(theme => ({
  head: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    verticalAlign: "bottom",
    "@media (max-width: 1140px)": {
      padding: "10px 15px 10px 0px ",

      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "left",
      letterSpacing: "0.1px",
    },

    "@media (max-width: 768px)": {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      padding: "14px 15px",
      "& th:nth-child(1)": {
        width: "122px",
        background: "red!important",
      },
    },
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "& th": {
      padding: "10px 16px ",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "left",
      letterSpacing: "0.5px",
    },

    "& td": {
      padding: "10px 16px ",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "right",
      letterSpacing: "0.5px",
      width: "248px",
      height: "31px",
    },

    "&:nth-of-type(odd)": {
      backgroundColor: "#F9FAFA",
    },

    "& td:nth-child(1)": {
      width: "152px",
    },

    "@media (max-width: 1140px)": {
      "& th": {
        padding: "0px 15px ",
        width: "122px",
        fontSize: "14px",
        lineHeight: "20px",
        textAlign: "left",
        letterSpacing: "0.1px",
        height: "47px",
      },

      "& td": {
        height: "47px",
        padding: "0px 15px ",
        fontSize: "14px",
        lineHeight: "20px",
        textAlign: "right",
        letterSpacing: "0.25px",
        width: "160px",
      },
      "& td:nth-child(1)": {
        width: "122px",
      },
      "& td:last-child": {
        width: "158px",
      },
    },
    "@media (max-width: 768px)": {
      "& th": {
        minWidth: "122px",
        width: "fit-content",
      },

      "& td": {
        width: "auto",
      },
    },
  },
}))(TableRow);

const StyledTopPagination = withStyles(theme => ({
  select: {
    width: "65px",
    height: "28px",
    border: "1px solid rgba(0, 0, 0, 0.24)",
    boxSizing: "border-box",
    textAlignLast: "center",
    borderRadius: "4px!important",
    background: "white",
  },
  root: {
    borderBottom: "none",
  },
}))(TablePagination);

const DetailWithThreeColumn = props => {
  const {
    t,
    material,
    tableBody,
    page,
    pageSize,
    changePage,
    changeFilters,
    changePageSize,
    changeDateRange,
    source,
  } = props;

  const domain = useContext(DomainContext);

  var lastDate = null;
  return (
    <div className={`${styles.fourColumnsTable} ${styles.tableContainer}`}>
      <div className={styles.tableWrapper}>
        <TableFilter
          pageSize={pageSize}
          changePageSize={changePageSize}
          changeFilters={changeFilters}
          changeDateRange={changeDateRange}
        />
        <div className={styles.tableWrapper}>
          <Table aria-label="customized table" className={styles.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell className={styles.tableHeadCell}>
                  <span className={styles.dateContainer}>{t("Datum")}</span>
                </StyledTableCell>
                <StyledTableCell className={styles.tableHeadCell} align="right">
                  <span>
                    LME{" "}
                    <nobr>
                      {t(material)} {t("Cash-Settlement")}
                    </nobr>
                  </span>
                </StyledTableCell>
                <StyledTableCell className={styles.tableHeadCell} align="right">
                  <span>
                    LME{" "}
                    <nobr>
                      {" "}
                      {t(material)} {t("3-Monate-Officials")}{" "}
                    </nobr>
                  </span>
                </StyledTableCell>
                <StyledTableCell className={styles.tableHeadCell} align="right">
                  <span>
                    <nobr>
                      LME {t(material)}-{t("Bestände")}
                    </nobr>
                  </span>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableBody.data !== undefined
                ? tableBody.data.map(row => {
                    let borderTop = false;
                    if (row.date !== null) {
                      let year = row.date.split("-");
                      year = year[2];
                      if (lastDate === null) {
                        lastDate = year;
                      }
                      borderTop = lastDate != year;
                      lastDate = year;
                    }

                    return (
                      <StyledTableRow
                        className={`${borderTop ? styles.borderTop : ""}`}
                      >
                        <StyledTableCell component="th" scope="row">
                          {formatDate(row.date, domain)}{" "}
                          {row.month != null ? monthName(row.month) : ""}{" "}
                          {row.quarter != null ? "Q" + row.quarter : ""}{" "}
                          {row.year}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumberMarktdatenTables(
                            domain,
                            row.settlement,
                            2
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumberMarktdatenTables(
                            domain,
                            row.three_month,
                            2
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumberMarktdatenTables(domain, row.stock, 2)}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </div>
        <TableFooter
          changePage={changePage}
          count={tableBody.totalPages * pageSize}
          pageSize={pageSize}
          tableBody={tableBody}
          page={page}
        />
        <LinkToSource to={source} />
      </div>
    </div>
  );
};

export default withNamespaces()(DetailWithThreeColumn);
