import React, { useEffect, useState, useCallback } from "react";

import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/i18n";
import MarktdatenDetailFourColumn from "./DetailWithFourColumn";

import MarktdatenDetailTwoColumn from "./DetailWithTwoColumn";
import { queryDetail, prepareDateForQuery } from "./utils";
const DetailTable = props => {
  const [period, setPeriod] = useState("day");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [table, setTable] = useState({});

  useEffect(() => {
    getInfo();
  }, [pageSize]);

  const changePageSize = newPageSize => {
    setPageSize(
      isNaN(newPageSize.target.value)
        ? newPageSize.target.value.value
        : newPageSize.target.value
    );
    setPage(1);
  };
  useEffect(() => {
    if (startDate === null && endDate === null) {
      getInfo();
    }
  }, [period]);

  useEffect(() => {
    if (startDate != null && endDate != null) {
      getInfo();
    }
  }, [endDate]);

  useEffect(() => {
    if (startDate != null && endDate != null) {
      getInfo();
    }
  }, [startDate]);

  const changeDateRange = values => {
    setStartDate(prepareDateForQuery(values[0]));
    setEndDate(prepareDateForQuery(values[1]));
    setPeriod(null);
  };

  const changeFilters = newPeriod => {
    setStartDate(null);
    setEndDate(null);
    setPeriod(newPeriod);
    setPage(1);
  };

  useEffect(() => {
    getInfo();
  }, [page]);

  const changePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const getInfo = () => {
    var query = queryDetail(
      page,
      pageSize,
      pathContext.table,
      pathContext.id,
      period,
      startDate,
      endDate
    );

    fetch(
      "https://2xerhhcoxf.execute-api.eu-central-1.amazonaws.com/dev/graphql",
      {
        method: "POST",
        headers: {},
        body: JSON.stringify({
          query,
        }),
      }
    )
      .then(r => r.json())
      .then(rst => {
        setTable(rst.data.detail);
      })
      .catch(Any => {
        console.log(Any);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);
  const { t, pathContext, pageContext } = props;

  return (
    <I18nextProvider i18n={i18n} defaultNS={"default"}>
      <div>
        {pathContext.table === "lme_price" ? (
          <MarktdatenDetailFourColumn
            page={page}
            pageSize={pageSize}
            changePageSize={changePageSize}
            material={pageContext.name}
            tableBody={table}
            changePage={changePage}
            changeFilters={changeFilters}
            changeDateRange={changeDateRange}
            source={props.source}
          />
        ) : (
          <MarktdatenDetailTwoColumn
            page={page}
            pageSize={pageSize}
            changePageSize={changePageSize}
            material={pageContext.name}
            tableBody={table}
            changePage={changePage}
            changeFilters={changeFilters}
            changeDateRange={changeDateRange}
            pageId={pathContext.id}
          />
        )}
      </div>
    </I18nextProvider>
  );
};

export default DetailTable;
