import React, { useState, useEffect, useRef } from "react";
import styles from "./FooterAndFilters.module.scss";
import "./filterStyles.css";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/styles";
import DatePicker from "./DatePicker";


import { withNamespaces } from "react-i18next";
const StyledTopPagination = withStyles(theme => ({
  select: {
    width: "65px",
    height: "28px",
    border: "1px solid rgba(0, 0, 0, 0.24)",
    boxSizing: "border-box",
    textAlignLast: "center",
    borderRadius: "4px!important",
    background: "white",
  },
  menuItem: {
    width: "100px",
    height: "48px",
  },

  root: {
    borderBottom: "none",
    paddingLeft: "0",
    "@media (max-width: 768px)": {
      width: "100%",
      margin: "0 auto",
      display: "flex",
      padding: "0",
      height: "53px",
      justifyContent: "center",
      borderTop: "1px solid rgba(0, 0, 0, 0.12);",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12);",
      marginTop: "16px",
    },

    "& .MuiTablePagination-selectLabel": { color: "rgba(0, 0, 0, 0.6)" },
    "& .MuiInputBase-root": {
      height: "28px",
    },
    "& .MuiInputBase-input": {
      height: "28px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media (max-width: 768px)": {
      "& .MuiToolbar-root ": {
        paddingRight: "65px",
      },
    },
  },
}))(TablePagination);

const TableFilter = props => {
  const [selectedFilter, setSelectedFilter] = useState("1T");

  const [calendarKey, setCalendarKey] = useState(1);
  const { t, changeFilters, changePageSize, pageSize, changeDateRange } = props;

  const disableFilters = values => {
    if (values[0] !== null && values[1] !== null) {
      setSelectedFilter(null);
      changeDateRange(values);
    }
  };

  const selecteFilter = value => {
    setSelectedFilter(value);
    setCalendarKey(calendarKey + 1);
  };
 
  return (
    <div className={styles.filters} >
      <div className={styles.paginationDropdownContainer}>
      <StyledTopPagination
        className={styles.test}
        rowsPerPageOptions={[
          { value: 10, label: "10" },
          { value: 20, label: "20" },
          { value: 50, label: "50" },
          { value: 100, label: "100" },
          { value: 99999999, label: "All" },
        ]}
        rowsPerPage={pageSize}
        onRowsPerPageChange={changePageSize}
        nextIconButtonProps={{ display: "none" }}
        nextIconButtonProps={{ style: { display: "none" } }}
        labelRowsPerPage={t("Reihen:")}
        labelDisplayedRows={row => <></>}
        backIconButtonProps={{ style: { display: "none" } }}
      />
</div>
      <div className={styles.filterOptions}>
        <div className={styles.groupFilter}>
          <span
            className={`${styles.dateBubble} ${
              selectedFilter === "1T" ? styles.isActive : ""
            }`}
            onClick={() => {
              selecteFilter("1T");
              changeFilters("day");
            }}
          >
            {t("1T")}
          </span>
          <span
            className={`${styles.dateBubble} ${
              selectedFilter === "1M" ? styles.isActive : ""
            }`}
            onClick={() => {
              selecteFilter("1M");
              changeFilters("month");
            }}
          >
            1M
          </span>
          <span
            className={`${styles.dateBubble} ${
              selectedFilter === "1Q" ? styles.isActive : ""
            }`}
            onClick={() => {
              selecteFilter("1Q");
              changeFilters("quarter");
            }}
          >
            1Q
          </span>
          <span
            className={`${styles.dateBubble} ${
              selectedFilter === "1J" ? styles.isActive : ""
            }`}
            onClick={() => {
              selecteFilter("1J");
              changeFilters("year");
            }}
          > 
            {t("1J")}
          </span>
          <DatePicker
            key={calendarKey}
            changeDateRange={disableFilters}
            lightCalendar={selectedFilter === null}
          />
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(TableFilter);
